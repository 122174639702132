













































import { Vue, Component } from 'vue-property-decorator';
import Copyright from '../components/copyright.vue'
import { LayoutStoreModule } from '@/store/LayoutModule'
import { UserStoreModule } from '@/store/UserModule'
import { addRouteByPermission, CusRoute } from '@/router';
@Component({
  components:{
    Copyright
  }
})
export default class LoginAndRegister extends Vue{
  form: {[key: string]: any} = {
    account: {
      value: '',
      reg: /.{1,}/,
      tip: '',
      showError: false,
    },
    password: {
      value: '',
      reg: /.{1,12}/,
      tip: this.$t('register_login_tip'),
      showError: false,
    },
  }
  logining = false;
  imgCoverList = [
    require('../assets/images/login_bgm1.jpg'),
    require('../assets/images/login_bgm2.jpg'),
    require('../assets/images/login_bgm3.jpg'),
    require('../assets/images/login_bgm4.jpg'),
    require('../assets/images/login_bgm5.jpg'),
    require('../assets/images/login_bgm6.jpg'),
  ];
  loadTime = 0;
  controllImg = true;
  currenImg = 0;
  mounted(){
    // 切换图片
    this.loadTime = setInterval(()=>{
      this.controllImg = !this.controllImg;
      let cur = this.currenImg;
      if ( cur+1 == this.imgCoverList.length ){
        cur = 0;
      } else {
        cur ++;
      }
      this.currenImg = cur;
    },6000)
  }
  beforeRouteLeave(to: CusRoute, from: CusRoute, next: Function){
    clearInterval(this.loadTime);
    next();
  }
  // 登录
  async submit(){
    let isPass = true;
    if(!this.form.account.reg.test(this.form.account.value)){
      this.form.account.showError = true;
      this.form.account.tip = this.$t('pleaseInputAccount')
      isPass = false;
    }
    if(!this.form.password.reg.test(this.form.password.value)){
      this.form.password.showError = true;
      this.form.password.tip = this.$t('register_login_tip')
      isPass = false;
    }
    if(!isPass || this.logining) return;
    this.logining = true;
    
    const ret = await UserStoreModule.userLogin({
      username: this.form.account.value,
      password: this.form.password.value,
      fkPlatformType: 'get_ib',
      grant_type: 'password',
    })
    // 如果登录成功
    if (UserStoreModule.isLogin) {
      LayoutStoreModule.setMenuAside({
        isSupperPermission: UserStoreModule.isSupperPermission, 
        permission: UserStoreModule.myPermission,
        schoolList: UserStoreModule.mySchool
      });
      addRouteByPermission(UserStoreModule.isSupperPermission, UserStoreModule.myPermission);
      this.form.account.value = '';
      this.form.password.value = '';
      this.$router.push({name: 'adminIndex'});
    }
    this.logining = false;
  }
  resetValidate(type: string){
    this.form[type].showError = false;
  }
}
